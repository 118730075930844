var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        [
          _c(
            "el-form",
            { attrs: { "label-position": "top" } },
            [
              _vm.data.type != "grid"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "字段标识" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.data.model,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "model", $$v)
                          },
                          expression: "data.model",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.data.type != "grid"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "标题" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.data.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "name", $$v)
                          },
                          expression: "data.name",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              Object.keys(_vm.data.options).indexOf("width") >= 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "宽度" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.data.options.width,
                          callback: function ($$v) {
                            _vm.$set(_vm.data.options, "width", $$v)
                          },
                          expression: "data.options.width",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              Object.keys(_vm.data.options).indexOf("height") >= 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "高度" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.data.options.height,
                          callback: function ($$v) {
                            _vm.$set(_vm.data.options, "height", $$v)
                          },
                          expression: "data.options.height",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              Object.keys(_vm.data.options).indexOf("size") >= 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "大小" } },
                    [
                      _vm._v(" 宽度 "),
                      _c("el-input", {
                        staticStyle: { width: "90px" },
                        attrs: { size: "small", type: "number" },
                        model: {
                          value: _vm.data.options.size.width,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.data.options.size,
                              "width",
                              _vm._n($$v)
                            )
                          },
                          expression: "data.options.size.width",
                        },
                      }),
                      _vm._v(" 高度 "),
                      _c("el-input", {
                        staticStyle: { width: "90px" },
                        attrs: { size: "small", type: "number" },
                        model: {
                          value: _vm.data.options.size.height,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.data.options.size,
                              "height",
                              _vm._n($$v)
                            )
                          },
                          expression: "data.options.size.height",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              Object.keys(_vm.data.options).indexOf("placeholder") >= 0 &&
              (_vm.data.type != "time" || _vm.data.type != "date")
                ? _c(
                    "el-form-item",
                    { attrs: { label: "占位" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.data.options.placeholder,
                          callback: function ($$v) {
                            _vm.$set(_vm.data.options, "placeholder", $$v)
                          },
                          expression: "data.options.placeholder",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              Object.keys(_vm.data.options).indexOf("inline") >= 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "布剧" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "small" },
                          model: {
                            value: _vm.data.options.inline,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.options, "inline", $$v)
                            },
                            expression: "data.options.inline",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: false } }, [
                            _vm._v("块级"),
                          ]),
                          _c("el-radio-button", { attrs: { label: true } }, [
                            _vm._v("行内"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              Object.keys(_vm.data.options).indexOf("showInput") >= 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "显示输入框" } },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.data.options.showInput,
                          callback: function ($$v) {
                            _vm.$set(_vm.data.options, "showInput", $$v)
                          },
                          expression: "data.options.showInput",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              Object.keys(_vm.data.options).indexOf("min") >= 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "最小值" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small", min: 0, max: 100, step: 1 },
                        model: {
                          value: _vm.data.options.min,
                          callback: function ($$v) {
                            _vm.$set(_vm.data.options, "min", $$v)
                          },
                          expression: "data.options.min",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              Object.keys(_vm.data.options).indexOf("max") >= 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "最大值" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small", min: 0, max: 100, step: 1 },
                        model: {
                          value: _vm.data.options.max,
                          callback: function ($$v) {
                            _vm.$set(_vm.data.options, "max", $$v)
                          },
                          expression: "data.options.max",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              Object.keys(_vm.data.options).indexOf("step") >= 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "步长" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small", min: 0, max: 100, step: 1 },
                        model: {
                          value: _vm.data.options.step,
                          callback: function ($$v) {
                            _vm.$set(_vm.data.options, "step", $$v)
                          },
                          expression: "data.options.step",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.data.type == "select" || _vm.data.type == "imgupload"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "多选" } },
                    [
                      _c("el-switch", {
                        attrs: { size: "small" },
                        on: { change: _vm.handleSelectMuliple },
                        model: {
                          value: _vm.data.options.multiple,
                          callback: function ($$v) {
                            _vm.$set(_vm.data.options, "multiple", $$v)
                          },
                          expression: "data.options.multiple",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.data.type == "select"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "可搜索" } },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.data.options.filterable,
                          callback: function ($$v) {
                            _vm.$set(_vm.data.options, "filterable", $$v)
                          },
                          expression: "data.options.filterable",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              Object.keys(_vm.data.options).indexOf("allowHalf") >= 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "'允许半边'" } },
                    [
                      _c("el-switch", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.data.options.allowHalf,
                          callback: function ($$v) {
                            _vm.$set(_vm.data.options, "allowHalf", $$v)
                          },
                          expression: "data.options.allowHalf",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              Object.keys(_vm.data.options).indexOf("showAlpha") >= 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "支持透明度选择" } },
                    [
                      _c("el-switch", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.data.options.showAlpha,
                          callback: function ($$v) {
                            _vm.$set(_vm.data.options, "showAlpha", $$v)
                          },
                          expression: "data.options.showAlpha",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              Object.keys(_vm.data.options).indexOf("showLabel") >= 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "是否显示标签" } },
                    [
                      _c("el-switch", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.data.options.showLabel,
                          callback: function ($$v) {
                            _vm.$set(_vm.data.options, "showLabel", $$v)
                          },
                          expression: "data.options.showLabel",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              Object.keys(_vm.data.options).indexOf("options") >= 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "选项" } },
                    [
                      [
                        _vm.data.type == "radio" ||
                        (_vm.data.type == "select" &&
                          !_vm.data.options.multiple)
                          ? [
                              _c(
                                "el-radio-group",
                                {
                                  staticStyle: { "line-height": "40px" },
                                  attrs: { size: "small" },
                                  model: {
                                    value: _vm.data.options.defaultValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data.options,
                                        "defaultValue",
                                        $$v
                                      )
                                    },
                                    expression: "data.options.defaultValue",
                                  },
                                },
                                [
                                  _c(
                                    "draggable",
                                    _vm._b(
                                      {
                                        attrs: {
                                          tag: "ul",
                                          list: _vm.data.options.options,
                                          handle: ".drag-item",
                                        },
                                      },
                                      "draggable",
                                      {
                                        group: { name: "options" },
                                        ghostClass: "ghost",
                                        handle: ".drag-item",
                                      },
                                      false
                                    ),
                                    _vm._l(
                                      _vm.data.options.options,
                                      function (item, index) {
                                        return _c(
                                          "li",
                                          { key: index },
                                          [
                                            _c(
                                              "el-radio",
                                              {
                                                staticStyle: {
                                                  "margin-right": "5px",
                                                },
                                                attrs: { label: item.value },
                                              },
                                              [
                                                _c("el-input", {
                                                  style: {
                                                    width: _vm.data.options
                                                      .showLabel
                                                      ? "80px"
                                                      : "180px",
                                                  },
                                                  attrs: { size: "mini" },
                                                  model: {
                                                    value: item.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.value",
                                                  },
                                                }),
                                                _vm.data.options.showLabel
                                                  ? _c("el-input", {
                                                      staticStyle: {
                                                        width: "80px",
                                                      },
                                                      attrs: { size: "mini" },
                                                      model: {
                                                        value: item.label,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "label",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.label",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "i",
                                              {
                                                staticClass: "drag-item",
                                                staticStyle: {
                                                  "font-size": "16px",
                                                  margin: "0 5px",
                                                  cursor: "move",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-rank",
                                                  attrs: { title: "拖动" },
                                                }),
                                              ]
                                            ),
                                            _c("el-button", {
                                              staticStyle: {
                                                padding: "4px",
                                                "margin-left": "5px",
                                              },
                                              attrs: {
                                                circle: "",
                                                plain: "",
                                                type: "danger",
                                                size: "mini",
                                                title: "删除",
                                                icon: "el-icon-delete",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleOptionsRemove(
                                                    index
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.data.type == "checkbox" ||
                        (_vm.data.type == "select" && _vm.data.options.multiple)
                          ? [
                              _c(
                                "el-checkbox-group",
                                {
                                  attrs: { size: "small" },
                                  model: {
                                    value: _vm.data.options.defaultValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data.options,
                                        "defaultValue",
                                        $$v
                                      )
                                    },
                                    expression: "data.options.defaultValue",
                                  },
                                },
                                [
                                  _c(
                                    "draggable",
                                    _vm._b(
                                      {
                                        attrs: {
                                          tag: "ul",
                                          list: _vm.data.options.options,
                                          handle: ".drag-item",
                                        },
                                      },
                                      "draggable",
                                      {
                                        group: { name: "options" },
                                        ghostClass: "ghost",
                                        handle: ".drag-item",
                                      },
                                      false
                                    ),
                                    _vm._l(
                                      _vm.data.options.options,
                                      function (item, index) {
                                        return _c(
                                          "li",
                                          { key: index },
                                          [
                                            _c(
                                              "el-checkbox",
                                              {
                                                staticStyle: {
                                                  "margin-right": "5px",
                                                },
                                                attrs: { label: item.value },
                                              },
                                              [
                                                _c("el-input", {
                                                  style: {
                                                    width: _vm.data.options
                                                      .showLabel
                                                      ? "90px"
                                                      : "180px",
                                                  },
                                                  attrs: { size: "mini" },
                                                  model: {
                                                    value: item.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.value",
                                                  },
                                                }),
                                                _vm.data.options.showLabel
                                                  ? _c("el-input", {
                                                      staticStyle: {
                                                        width: "90px",
                                                      },
                                                      attrs: { size: "mini" },
                                                      model: {
                                                        value: item.label,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "label",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.label",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "i",
                                              {
                                                staticClass: "drag-item",
                                                staticStyle: {
                                                  "font-size": "16px",
                                                  margin: "0 5px",
                                                  cursor: "move",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-rank",
                                                  attrs: { title: "拖动" },
                                                }),
                                              ]
                                            ),
                                            _c("el-button", {
                                              staticStyle: {
                                                padding: "4px",
                                                "margin-left": "5px",
                                              },
                                              attrs: {
                                                circle: "",
                                                plain: "",
                                                type: "danger",
                                                title: "删除",
                                                size: "mini",
                                                icon: "el-icon-delete",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleOptionsRemove(
                                                    index
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _c(
                          "div",
                          { staticStyle: { "margin-left": "22px" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: { click: _vm.handleAddOption },
                              },
                              [_vm._v("添加选项")]
                            ),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  )
                : _vm._e(),
              Object.keys(_vm.data.options).indexOf("defaultValue") >= 0 &&
              (_vm.data.type == "textarea" ||
                _vm.data.type == "input" ||
                _vm.data.type == "text" ||
                _vm.data.type == "rate" ||
                _vm.data.type == "color" ||
                _vm.data.type == "switch")
                ? _c(
                    "el-form-item",
                    { attrs: { label: "默认值" } },
                    [
                      _vm.data.type == "textarea"
                        ? _c("el-input", {
                            attrs: { size: "small", type: "textarea", rows: 5 },
                            model: {
                              value: _vm.data.options.defaultValue,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.options, "defaultValue", $$v)
                              },
                              expression: "data.options.defaultValue",
                            },
                          })
                        : _vm._e(),
                      _vm.data.type == "input"
                        ? _c("el-input", {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.data.options.defaultValue,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.options, "defaultValue", $$v)
                              },
                              expression: "data.options.defaultValue",
                            },
                          })
                        : _vm._e(),
                      _vm.data.type == "text"
                        ? _c("el-input", {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.data.options.defaultValue,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.options, "defaultValue", $$v)
                              },
                              expression: "data.options.defaultValue",
                            },
                          })
                        : _vm._e(),
                      _vm.data.type == "rate"
                        ? _c("el-rate", {
                            staticStyle: {
                              display: "inline-block",
                              "vertical-align": "middle",
                            },
                            attrs: {
                              size: "small",
                              max: _vm.data.options.max,
                              "allow-half": _vm.data.options.allowHalf,
                            },
                            model: {
                              value: _vm.data.options.defaultValue,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.options, "defaultValue", $$v)
                              },
                              expression: "data.options.defaultValue",
                            },
                          })
                        : _vm._e(),
                      _vm.data.type == "rate"
                        ? _c(
                            "el-button",
                            {
                              staticStyle: {
                                display: "inline-block",
                                "vertical-align": "middle",
                                "margin-left": "10px",
                              },
                              attrs: { size: "small", type: "text" },
                              on: {
                                click: function ($event) {
                                  _vm.data.options.defaultValue = 0
                                },
                              },
                            },
                            [_vm._v("清空")]
                          )
                        : _vm._e(),
                      _vm.data.type == "color"
                        ? _c("el-color-picker", {
                            attrs: {
                              "show-alpha": _vm.data.options.showAlpha,
                              size: "small",
                            },
                            model: {
                              value: _vm.data.options.defaultValue,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.options, "defaultValue", $$v)
                              },
                              expression: "data.options.defaultValue",
                            },
                          })
                        : _vm._e(),
                      _vm.data.type == "switch"
                        ? _c("el-switch", {
                            model: {
                              value: _vm.data.options.defaultValue,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.options, "defaultValue", $$v)
                              },
                              expression: "data.options.defaultValue",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.data.type == "time" || _vm.data.type == "date"
                ? [
                    _vm.data.type == "date"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "显示类型" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.data.options.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data.options, "type", $$v)
                                  },
                                  expression: "data.options.type",
                                },
                              },
                              [
                                _c("el-option", { attrs: { value: "year" } }),
                                _c("el-option", { attrs: { value: "month" } }),
                                _c("el-option", { attrs: { value: "date" } }),
                                _c("el-option", { attrs: { value: "dates" } }),
                                _c("el-option", {
                                  attrs: { value: "datetime" },
                                }),
                                _c("el-option", {
                                  attrs: { value: "datetimerange" },
                                }),
                                _c("el-option", {
                                  attrs: { value: "daterange" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.data.type == "time"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "是否为范围选择" } },
                          [
                            _c("el-switch", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.data.options.isRange,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data.options, "isRange", $$v)
                                },
                                expression: "data.options.isRange",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.data.type == "date"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "是否获取时间戳" } },
                          [
                            _c("el-switch", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.data.options.timestamp,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data.options, "timestamp", $$v)
                                },
                                expression: "data.options.timestamp",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    (!_vm.data.options.isRange && _vm.data.type == "time") ||
                    (_vm.data.type != "time" &&
                      _vm.data.options.type != "datetimerange" &&
                      _vm.data.options.type != "daterange")
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "占位" } },
                          [
                            _c("el-input", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.data.options.placeholder,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data.options, "placeholder", $$v)
                                },
                                expression: "data.options.placeholder",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.data.options.isRange ||
                    _vm.data.options.type == "datetimerange" ||
                    _vm.data.options.type == "daterange"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "开始时间" } },
                          [
                            _c("el-input", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.data.options.startPlaceholder,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.data.options,
                                    "startPlaceholder",
                                    $$v
                                  )
                                },
                                expression: "data.options.startPlaceholder",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.data.options.isRange ||
                    _vm.data.options.type == "datetimerange" ||
                    _vm.data.options.type == "daterange"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "结束时间" } },
                          [
                            _c("el-input", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.data.options.endPlaceholder,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.data.options,
                                    "endPlaceholder",
                                    $$v
                                  )
                                },
                                expression: "data.options.endPlaceholder",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      { attrs: { label: "格式" } },
                      [
                        _c("el-input", {
                          attrs: { size: "small" },
                          model: {
                            value: _vm.data.options.format,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.options, "format", $$v)
                            },
                            expression: "data.options.format",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.data.type == "time" &&
                    Object.keys(_vm.data.options).indexOf("isRange") >= 0
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "默认值" } },
                          [
                            !_vm.data.options.isRange
                              ? _c("el-time-picker", {
                                  key: "1",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    arrowControl: _vm.data.options.arrowControl,
                                    "value-format": _vm.data.options.format,
                                    size: "small",
                                  },
                                  model: {
                                    value: _vm.data.options.defaultValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data.options,
                                        "defaultValue",
                                        $$v
                                      )
                                    },
                                    expression: "data.options.defaultValue",
                                  },
                                })
                              : _vm._e(),
                            _vm.data.options.isRange
                              ? _c("el-time-picker", {
                                  key: "2",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "is-range": "",
                                    arrowControl: _vm.data.options.arrowControl,
                                    "value-format": _vm.data.options.format,
                                    size: "small",
                                  },
                                  model: {
                                    value: _vm.data.options.defaultValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data.options,
                                        "defaultValue",
                                        $$v
                                      )
                                    },
                                    expression: "data.options.defaultValue",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm.data.type == "imgupload"
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "最大上传数" } },
                      [
                        _c("el-input", {
                          attrs: { size: "small", type: "number" },
                          model: {
                            value: _vm.data.options.length,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.options, "length", _vm._n($$v))
                            },
                            expression: "data.options.length",
                          },
                        }),
                      ],
                      1
                    ),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "图片上传地址", required: true } },
                        [
                          _c("el-input", {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.data.options.action,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.options, "action", $$v)
                              },
                              expression: "data.options.action",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                  ]
                : _vm._e(),
              _vm.data.type == "blank"
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "绑定数据类型" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.data.options.defaultType,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.options, "defaultType", $$v)
                              },
                              expression: "data.options.defaultType",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: "String", label: "字符串" },
                            }),
                            _c("el-option", {
                              attrs: { value: "Object", label: "对象" },
                            }),
                            _c("el-option", {
                              attrs: { value: "Array", label: "数组" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.data.type == "grid"
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "栅格间隔" } },
                      [
                        _c("el-input", {
                          attrs: { size: "small", type: "number" },
                          model: {
                            value: _vm.data.options.gutter,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.options, "gutter", _vm._n($$v))
                            },
                            expression: "data.options.gutter",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "列配置项" } },
                      [
                        _c(
                          "draggable",
                          _vm._b(
                            {
                              attrs: {
                                tag: "ul",
                                list: _vm.data.columns,
                                handle: ".drag-item",
                              },
                            },
                            "draggable",
                            {
                              group: { name: "options" },
                              ghostClass: "ghost",
                              handle: ".drag-item",
                            },
                            false
                          ),
                          _vm._l(_vm.data.columns, function (item, index) {
                            return _c(
                              "li",
                              { key: index },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass: "drag-item",
                                    staticStyle: {
                                      "font-size": "16px",
                                      margin: "0 5px",
                                      cursor: "move",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-rank",
                                      attrs: { title: "拖动" },
                                    }),
                                  ]
                                ),
                                _c("el-input", {
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    placeholder: "栅格值",
                                    size: "mini",
                                    type: "number",
                                  },
                                  model: {
                                    value: item.span,
                                    callback: function ($$v) {
                                      _vm.$set(item, "span", _vm._n($$v))
                                    },
                                    expression: "item.span",
                                  },
                                }),
                                _c("el-button", {
                                  staticStyle: {
                                    padding: "4px",
                                    "margin-left": "5px",
                                  },
                                  attrs: {
                                    circle: "",
                                    plain: "",
                                    type: "danger",
                                    size: "mini",
                                    icon: "el-icon-delete",
                                    title: "删除",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleOptionsRemove(index)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                        _c(
                          "div",
                          { staticStyle: { "margin-left": "22px" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: { click: _vm.handleAddColumn },
                              },
                              [_vm._v("添加列")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "水平排列方式" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.data.options.justify,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.options, "justify", $$v)
                              },
                              expression: "data.options.justify",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: "start", label: "左对齐" },
                            }),
                            _c("el-option", {
                              attrs: { value: "end", label: "右对齐" },
                            }),
                            _c("el-option", {
                              attrs: { value: "center", label: "居中" },
                            }),
                            _c("el-option", {
                              attrs: {
                                value: "space-around",
                                label: "两侧间隔相等",
                              },
                            }),
                            _c("el-option", {
                              attrs: {
                                value: "space-between",
                                label: "两端对齐",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "垂直排列方式" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.data.options.align,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.options, "align", $$v)
                              },
                              expression: "data.options.align",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: "top", label: "顶对齐" },
                            }),
                            _c("el-option", {
                              attrs: { value: "middle", label: "居中" },
                            }),
                            _c("el-option", {
                              attrs: { value: "bottom", label: "底对齐" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.data.type != "grid" && _vm.data.type !== "text"
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "操作属性" } },
                      [
                        Object.keys(_vm.data.options).indexOf("readonly") >= 0
                          ? _c(
                              "el-checkbox",
                              {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.data.options.readonly,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data.options, "readonly", $$v)
                                  },
                                  expression: "data.options.readonly",
                                },
                              },
                              [_vm._v("只读")]
                            )
                          : _vm._e(),
                        Object.keys(_vm.data.options).indexOf("disabled") >= 0
                          ? _c(
                              "el-checkbox",
                              {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.data.options.disabled,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data.options, "disabled", $$v)
                                  },
                                  expression: "data.options.disabled",
                                },
                              },
                              [_vm._v("禁用")]
                            )
                          : _vm._e(),
                        Object.keys(_vm.data.options).indexOf("editable") >= 0
                          ? _c(
                              "el-checkbox",
                              {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.data.options.editable,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data.options, "editable", $$v)
                                  },
                                  expression: "data.options.editable",
                                },
                              },
                              [_vm._v("文本框可输")]
                            )
                          : _vm._e(),
                        Object.keys(_vm.data.options).indexOf("clearable") >= 0
                          ? _c(
                              "el-checkbox",
                              {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.data.options.clearable,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data.options, "clearable", $$v)
                                  },
                                  expression: "data.options.clearable",
                                },
                              },
                              [_vm._v("显示清除按钮")]
                            )
                          : _vm._e(),
                        Object.keys(_vm.data.options).indexOf("arrowControl") >=
                        0
                          ? _c(
                              "el-checkbox",
                              {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.data.options.arrowControl,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.data.options,
                                      "arrowControl",
                                      $$v
                                    )
                                  },
                                  expression: "data.options.arrowControl",
                                },
                              },
                              [_vm._v("使用箭头进行时间选择")]
                            )
                          : _vm._e(),
                        Object.keys(_vm.data.options).indexOf("isDelete") >= 0
                          ? _c(
                              "el-checkbox",
                              {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.data.options.isDelete,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data.options, "isDelete", $$v)
                                  },
                                  expression: "data.options.isDelete",
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                        Object.keys(_vm.data.options).indexOf("isEdit") >= 0
                          ? _c(
                              "el-checkbox",
                              {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.data.options.isEdit,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data.options, "isEdit", $$v)
                                  },
                                  expression: "data.options.isEdit",
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "校验" } },
                      [
                        Object.keys(_vm.data.options).indexOf("required") >= 0
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: { size: "small" },
                                    model: {
                                      value: _vm.data.options.required,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data.options,
                                          "required",
                                          $$v
                                        )
                                      },
                                      expression: "data.options.required",
                                    },
                                  },
                                  [_vm._v("必填")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        Object.keys(_vm.data.options).indexOf("dataType") >= 0
                          ? _c(
                              "el-select",
                              {
                                attrs: { size: "mini" },
                                model: {
                                  value: _vm.data.options.dataType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data.options, "dataType", $$v)
                                  },
                                  expression: "data.options.dataType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { value: "string", label: "字符串" },
                                }),
                                _c("el-option", {
                                  attrs: { value: "number", label: "数字" },
                                }),
                                _c("el-option", {
                                  attrs: { value: "boolean", label: "布尔值" },
                                }),
                                _c("el-option", {
                                  attrs: { value: "integer", label: "整数" },
                                }),
                                _c("el-option", {
                                  attrs: { value: "float", label: "浮点数" },
                                }),
                                _c("el-option", {
                                  attrs: { value: "url", label: "URL地址" },
                                }),
                                _c("el-option", {
                                  attrs: { value: "email", label: "邮箱地址" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        Object.keys(_vm.data.options).indexOf("pattern") >= 0
                          ? _c(
                              "div",
                              [
                                _c("el-input", {
                                  staticStyle: { width: "240px" },
                                  attrs: {
                                    size: "mini",
                                    placeholder: "填写正则表达式",
                                  },
                                  model: {
                                    value: _vm.data.options.pattern,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data.options, "pattern", $$v)
                                    },
                                    expression: "data.options.pattern",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }