var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "created-form-container", staticStyle: { height: "100%" } },
    [
      _vm.data.list.length == 0
        ? _c("div", { staticClass: "form-empty" }, [
            _vm._v("从顶部拖拽来添加字段"),
          ])
        : _vm._e(),
      _c(
        "el-form",
        {
          staticStyle: { height: "calc(100% - 10px)" },
          attrs: {
            size: _vm.data.config.size,
            "label-suffix": ":",
            "label-position": _vm.data.config.labelPosition,
            "label-width": _vm.data.config.labelWidth + "px",
          },
        },
        [
          _c(
            "draggable",
            _vm._b(
              {
                staticStyle: { height: "100%" },
                on: { end: _vm.handleMoveEnd, add: _vm.handleAddFormItem },
                model: {
                  value: _vm.data.list,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "list", $$v)
                  },
                  expression: "data.list",
                },
              },
              "draggable",
              {
                group: "people",
                ghostClass: "ghost",
                animation: 200,
                handle: ".drag-form",
              },
              false
            ),
            [
              _c(
                "transition-group",
                {
                  staticClass: "created-form-list",
                  staticStyle: { "min-height": "calc(100% - 10px)" },
                  attrs: { name: "fade", tag: "div" },
                },
                [
                  _vm._l(_vm.data.list, function (element, index) {
                    return [
                      element.type == "grid"
                        ? [
                            element && element.key
                              ? _c(
                                  "el-row",
                                  {
                                    key: element.key,
                                    staticClass: "created-col created-view",
                                    class: {
                                      active:
                                        _vm.selectFormData.key == element.key,
                                    },
                                    attrs: {
                                      type: "flex",
                                      gutter: element.options.gutter
                                        ? element.options.gutter
                                        : 0,
                                      justify: element.options.justify,
                                      align: element.options.align,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.handleSelectForm(index)
                                      },
                                    },
                                  },
                                  [
                                    _vm._l(
                                      element.columns,
                                      function (col, colIndex) {
                                        return _c(
                                          "el-col",
                                          {
                                            key: colIndex,
                                            attrs: {
                                              span: col.span ? col.span : 0,
                                            },
                                          },
                                          [
                                            _c(
                                              "draggable",
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    "no-transition-on-drag": true,
                                                  },
                                                  on: {
                                                    end: _vm.handleMoveEnd,
                                                    add: function ($event) {
                                                      return _vm.handleAddFormCol(
                                                        $event,
                                                        element,
                                                        colIndex
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: col.list,
                                                    callback: function ($$v) {
                                                      _vm.$set(col, "list", $$v)
                                                    },
                                                    expression: "col.list",
                                                  },
                                                },
                                                "draggable",
                                                {
                                                  group: "people",
                                                  ghostClass: "ghost",
                                                  animation: 200,
                                                  handle: ".drag-form",
                                                },
                                                false
                                              ),
                                              [
                                                _c(
                                                  "transition-group",
                                                  {
                                                    staticClass:
                                                      "created-col-list",
                                                    attrs: {
                                                      name: "fade",
                                                      tag: "div",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      col.list,
                                                      function (el, i) {
                                                        return [
                                                          el.key
                                                            ? _c(
                                                                "ShowFormItem",
                                                                {
                                                                  key: el.key,
                                                                  attrs: {
                                                                    element: el,
                                                                    select:
                                                                      _vm.selectFormData,
                                                                    index: i,
                                                                    data: col,
                                                                  },
                                                                  on: {
                                                                    "update:select":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.selectFormData =
                                                                          $event
                                                                      },
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    _vm.selectFormData.key == element.key
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "created-view-action created-col-action",
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-delete",
                                              attrs: { title: "删除" },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.handleDeleteFormItem(
                                                    index
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.selectFormData.key == element.key
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "created-view-drag created-col-drag",
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-sort drag-form",
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        : [
                            element && element.key
                              ? _c("ShowFormItem", {
                                  key: element.key,
                                  attrs: {
                                    element: element,
                                    select: _vm.selectFormData,
                                    index: index,
                                    data: _vm.data,
                                  },
                                  on: {
                                    "update:select": function ($event) {
                                      _vm.selectFormData = $event
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }