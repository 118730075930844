var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "fm2-container", staticStyle: { height: "100%" } },
    [
      _c(
        "el-header",
        {
          staticStyle: {
            height: "auto",
            padding: "0",
            "border-bottom": "1px solid #ccc",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "components-list",
              staticStyle: { "padding-bottom": "0" },
            },
            [
              _vm.basicFields.length
                ? [
                    _c(
                      "draggable",
                      _vm._b(
                        {
                          staticStyle: { "padding-bottom": "0" },
                          attrs: {
                            tag: "ul",
                            list: _vm.basicComponents,
                            move: _vm.handleMove,
                          },
                          on: {
                            end: _vm.handleMoveEnd,
                            start: _vm.handleMoveStart,
                          },
                        },
                        "draggable",
                        {
                          group: { name: "people", pull: "clone", put: false },
                          sort: false,
                          ghostClass: "ghost",
                        },
                        false
                      ),
                      [
                        _vm._l(_vm.basicComponents, function (item, index) {
                          return [
                            _vm.basicFields.indexOf(item.type) >= 0
                              ? _c(
                                  "li",
                                  {
                                    key: index,
                                    staticClass: "form-edit-created-label",
                                    class: { "no-put": item.type == "divider" },
                                  },
                                  [
                                    _c("a", [
                                      _c("i", {
                                        staticClass: "icon iconfont",
                                        class: item.icon,
                                      }),
                                      _c("span", [_vm._v(_vm._s(item.name))]),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _c(
        "el-main",
        { staticClass: "fm2-main" },
        [
          _c(
            "el-container",
            [
              _c(
                "el-container",
                {
                  staticClass: "center-container",
                  attrs: { direction: "vertical" },
                },
                [
                  _c(
                    "el-main",
                    { class: { "created-empty": _vm.data.list.length == 0 } },
                    [
                      !_vm.resetJson
                        ? _c("ShowForm", {
                            attrs: {
                              data: _vm.data,
                              select: _vm.formDataSelect,
                            },
                            on: {
                              "update:select": function ($event) {
                                _vm.formDataSelect = $event
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-header",
                    { staticClass: "btn-bar", staticStyle: { height: "45px" } },
                    [
                      _vm._t("action"),
                      _vm.clearable
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "text",
                                size: "mini",
                                icon: "el-icon-delete",
                              },
                              on: { click: _vm.handleClear },
                            },
                            [_vm._v("清空")]
                          )
                        : _vm._e(),
                      _vm.preview
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "text",
                                size: "mini",
                                icon: "el-icon-view",
                              },
                              on: { click: _vm.handlePreview },
                            },
                            [_vm._v("预览")]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-aside",
                { staticClass: "created-config-container" },
                [
                  _c(
                    "el-container",
                    [
                      _c(
                        "el-header",
                        {
                          staticStyle: { "font-size": "0" },
                          attrs: { height: "45px" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "config-tab",
                              class: { active: _vm.configTab == "created" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleConfigSelect("created")
                                },
                              },
                            },
                            [_vm._v("字段属性")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "config-tab",
                              class: { active: _vm.configTab == "form" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleConfigSelect("form")
                                },
                              },
                            },
                            [_vm._v("表单属性")]
                          ),
                        ]
                      ),
                      _c(
                        "el-main",
                        { staticClass: "config-content" },
                        [
                          _c("config", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.configTab == "created",
                                expression: "configTab == 'created'",
                              },
                            ],
                            attrs: { data: _vm.formDataSelect },
                          }),
                          _c("CreatedFormConfig", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.configTab == "form",
                                expression: "configTab == 'form'",
                              },
                            ],
                            attrs: { data: _vm.data.config },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Model",
                {
                  ref: "formPreview",
                  attrs: {
                    visible: _vm.previewVisible,
                    width: "1000px",
                    form: "",
                  },
                  on: {
                    "on-close": function ($event) {
                      _vm.previewVisible = false
                    },
                  },
                },
                [
                  _vm.previewVisible
                    ? _c("CreatedForm", {
                        ref: "generateForm",
                        attrs: {
                          insite: true,
                          data: _vm.data,
                          remote: _vm.remoteFuncs,
                        },
                        on: { "on-change": _vm.handleDataChange },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "blank",
                              fn: function (scope) {
                                return [
                                  _vm._v(" Width "),
                                  _c("el-input", {
                                    staticStyle: { width: "100px" },
                                    model: {
                                      value: scope.model.blank.width,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.model.blank,
                                          "width",
                                          $$v
                                        )
                                      },
                                      expression: "scope.model.blank.width",
                                    },
                                  }),
                                  _vm._v(" Height "),
                                  _c("el-input", {
                                    staticStyle: { width: "100px" },
                                    model: {
                                      value: scope.model.blank.height,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.model.blank,
                                          "height",
                                          $$v
                                        )
                                      },
                                      expression: "scope.model.blank.height",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1939583605
                        ),
                      })
                    : _vm._e(),
                  _c(
                    "template",
                    { slot: "action" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleGetData },
                        },
                        [_vm._v("获取数据")]
                      ),
                      _c("el-button", { on: { click: _vm.handleReset } }, [
                        _vm._v("重置"),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "Model",
                {
                  ref: "jsonPreview",
                  attrs: { visible: _vm.jsonVisible, width: "800px", form: "" },
                  on: {
                    "on-close": function ($event) {
                      _vm.jsonVisible = false
                    },
                  },
                },
                [
                  _c("codemirror", {
                    ref: "myEditor",
                    staticStyle: { height: "100%" },
                    model: {
                      value: _vm.jsonTemplate,
                      callback: function ($$v) {
                        _vm.jsonTemplate = $$v
                      },
                      expression: "jsonTemplate",
                    },
                  }),
                  _c(
                    "template",
                    { slot: "action" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "json-btn",
                          attrs: {
                            type: "primary",
                            "data-clipboard-text": _vm.jsonCopyValue,
                          },
                        },
                        [_vm._v("复制")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }